import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const addSong = createAsyncThunk("songs/add", async (song) => {
	const response = await axios.post(
		"https://www.plaotjesdraejen.nl/restapi/apis/create.php",
		{
			artist: song.artist,
			title: song.title,
			omschrijving: song.omschrijving,
			user: song.user,
			userid: song.userId,
		}
	);
	return response.data;
});

export { addSong };
