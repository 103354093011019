import { useState, useEffect } from "react";
import { addSong } from "../store";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Button from "./Button";
import TextInput from "./TextInput";
import { useThunk } from "../hooks/use-thunk";
import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function Header(time) {
	const [doCreateSong, isCreatingSong, isCreatingSongError] = useThunk(addSong);
	const [artiest, setArtiest] = useState("");
	const [titel, setTitel] = useState("");
	const [omschrijving, setOmschrijving] = useState("");
	const [request, setRequest] = useState(false);
	const [duration, setDuration] = useState(0);
	const navigate = useNavigate();
	const width = window.innerWidth;
	const user = localStorage.getItem("user");

	useEffect(() => {
		if (time.time > 0) {
			setDuration(time.time);
			setRequest(true);
		}
	}, [time.time]);

	const handleSubmit = (e) => {
		e.preventDefault();

		const artist = e.target.artiest.value;
		const title = e.target.titel.value;
		const omschrijving = e.target.omschrijving.value;
		const song = {
			artist: artist,
			title: title,
			omschrijving: omschrijving,
			user: JSON.parse(localStorage.getItem("user")),
			userId: localStorage.getItem("userId"),
		};

		if (song.artist !== "" && song.title !== "") {
			setRequest(true);
			setDuration(100);
			doCreateSong(song);
		}

		setArtiest("");
		e.target.artiest.value = "";
		setTitel("");
		e.target.titel.value = "";
		setOmschrijving("");
		e.target.omschrijving.value = "";
	};
	const handleNavigation = () => {
		navigate("/");
	};

	const getCountDownTimer = () => {
		const renderTime = ({ remainingTime }) => {
			if (remainingTime === 0) {
				setRequest(false);
				return <div>0</div>;
			}
			localStorage.setItem("time", remainingTime);
			return <div>{remainingTime}</div>;
		};

		return (
			<CountdownCircleTimer
				size={60}
				strokeWidth={4}
				isPlaying
				duration={duration}
				colors={["#F9F", "#C9F", "#99F", "#69F", "#39f", "#09F"]}
				colorsTime={[100, 50, 30, 15, 5, 0]}>
				{renderTime}
			</CountdownCircleTimer>
		);
	};

	const getButton = () => {
		return request ? (
			<div className="grid grid-cols-1 place-items-center">
				Sorry, even 100 tellen wachten voor je het volgende verzoekje aanvraagt!
			</div>
		) : (
			<div className="grid grid-cols-1 place-items-center">
				<Button type="Submit" loading={isCreatingSong} primary rounded>
					Verzoekplaat aanvragen
				</Button>
			</div>
		);
	};

	const responsive =
		width < 505
			? "flex flex-col m-1"
			: "flex flex-row justify-between items-center m-1";

	return (
		<div>
			<form onSubmit={handleSubmit}>
				<div className="flex flex-row justify-between items-center">
					<GoArrowLeft
						className="text-blue-500 cursor-pointer"
						onClick={handleNavigation}
					/>
					{getCountDownTimer()}
				</div>

				<div className={responsive}>
					<h1 className="text-xl text-blue-500">
						Verzoekjes van {JSON.parse(user)}:
					</h1>

					{isCreatingSongError && "Error creating request..."}
				</div>
				<div className={responsive}>
					<TextInput
						onChange={(e) => setArtiest(e.target.value)}
						value={artiest}
						name="artiest"
						label="Artiest:"
						type={"text"}
						placeholder={"Voer artiest in"}
					/>
					<TextInput
						onChange={(e) => setTitel(e.target.value)}
						value={titel}
						name="titel"
						label="Titel:"
						type={"text"}
						placeholder={"Voer een titel in..."}
					/>
				</div>
				<div className={responsive}>
					<TextInput
						onChange={(e) => setOmschrijving(e.target.value)}
						value={omschrijving}
						name="omschrijving"
						label="Vertel ons waarom?"
						type={"text"}
						placeholder={"Verhaal achter de plaat"}
					/>
				</div>
				{getButton()}
			</form>
			<hr className=" m-1.5 items-center border-blue-500 border-1" />
		</div>
	);
}

export default Header;
