import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchSongs = createAsyncThunk("users/fetch", async () => {
	const response = await axios.get(
		"https://plaotjesdraejen.nl/restapi/apis/read_all.php"
	);

	// DEV ONLY!!!
	await pause(1000);

	return response.data;
});

//DEV ONLY!!!
const pause = (duration) => {
	return new Promise((resolve) => {
		setTimeout(resolve, duration);
	});
};

export { fetchSongs };
