import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const deleteSong = createAsyncThunk("songs/delete", async (id) => {
	const response = await axios.post(
		`https://www.plaotjesdraejen.nl/restapi/apis/delete.php?id=${id}`
	);
	return response.data;
});

export { deleteSong };
