function TextInput({ handleChange, text, name, type, label, placeholder }) {
	return (
		<div className="flex flex-row justify-between items-stretch m-1 ">
			<label className="mr-3 mb-1">{label}</label>
			<input
				className="border h-8 align-right"
				value={text}
				onChange={handleChange}
				name={name}
				type={type}
				placeholder={placeholder}
			/>
		</div>
	);
}

export default TextInput;
