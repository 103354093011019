import { createSlice } from "@reduxjs/toolkit";
import { fetchSongs } from "../thunks/fetchSongs";
import { addSong } from "../thunks/addSong";
import { deleteSong } from "../thunks/deleteSong";

const songsSlice = createSlice({
	name: "songs",
	initialState: {
		data: [],
		isLoading: false,
		error: null,
	},
	extraReducers(builder) {
		builder.addCase(fetchSongs.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(fetchSongs.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(fetchSongs.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error;
		});

		builder.addCase(addSong.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(addSong.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data.push(action.payload);
		});
		builder.addCase(addSong.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error;
		});

		builder.addCase(deleteSong.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(deleteSong.fulfilled, (state, action) => {
			state.isLoading = false;
			state.data = action.payload;
		});
		builder.addCase(deleteSong.rejected, (state, action) => {
			state.isLoading = false;
			state.error = action.error;
		});
	},
});

export const songsReducer = songsSlice.reducer;
