import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fetchSongs, deleteSong } from "../store";
import Skeleton from "./Skeleton";
import Header from "./Header";
import Button from "./Button";
import { useThunk } from "../hooks/use-thunk";
import { GoTrashcan } from "react-icons/go";

function RequestList() {
	const [doDeleteSong, isLoadingRemainSongs, isLoadingRemainSongsError] =
		useThunk(deleteSong);

	const [doFetchSongs, isLoadingSongs, isLoadingSongsError] =
		useThunk(fetchSongs);

	const { data } = useSelector((state) => {
		return state.songs;
	});
	const navigate = useNavigate();
	const showDeleteButton = false;

	useEffect(() => {
		const user = localStorage.getItem("user");
		if (user === null) {
			navigate("/");
		} else {
			doFetchSongs();
		}
	}, [doFetchSongs, navigate]);

	const delSong = (id) => {
		doDeleteSong(id);
	};

	let content;

	if (isLoadingSongs || isLoadingRemainSongs) {
		content = <Skeleton times={3} className="h-10 w-full" />;
	} else if (isLoadingSongsError || isLoadingRemainSongsError) {
		content = <div>De wachtrij met verzoekjes is nog leeg.</div>;
	} else {
		content = data.map((song) => {
			return (
				<div key={uuidv4()} className="mb-2 ml-1 border rounded">
					<div className="flex p-2 justify-between items-center cursor-pointer">
						{song.Artist} - {song.Title}
						{showDeleteButton ? (
							<Button
								type="Submit"
								onClick={() => {
									delSong(song.Id);
								}}>
								<GoTrashcan />
							</Button>
						) : null}
					</div>
				</div>
			);
		});
	}

	return (
		<div>
			<Header time={parseInt(localStorage.getItem("time"))} />
			<div className="ml-1 mb-1 font-bold text-blue-500">
				Lijst met verzoekjes:
			</div>
			{content}
		</div>
	);
}

export default RequestList;
